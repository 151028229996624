import React, { useState } from 'react'
import './BlogsContent.css'
import { Link } from 'react-router-dom'
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Pagination } from 'react-bootstrap'
import { blogsData } from '../../Common_Component/Array_response/Blogs/Blog_data'
import { useParams } from 'react-router-dom';


const BlogsContent = () => {

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 6;

    // Calculate the sliced data for the current page
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentData = blogsData.slice(indexOfFirstItem, indexOfLastItem);

    // Calculate total pages
    const totalPages = Math.ceil(blogsData.length / itemsPerPage);

    // Handle page change
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const { id } = useParams();
    const blog = blogsData.find((blog) => blog.id === parseInt(id));


    return (
        <section className='BlogsContent'>

            <div className='row'>
                {blogsData.map((blog, index) => (
                    <div className='col-lg-6 mb-4' key={blog.id}>
                        <Link to={`/blog-detail/${blog.id}`}>
                            <div className='blog_card_holder'>
                                <div className='img_div'>
                                    <img className='blog_img' src={`${process.env.PUBLIC_URL}${blog.image}`} alt={blog.title} />
                                    <div className='overlay_content'>
                                        <Link className='data' to={`/blog-detail/${blog.id}`}>{blog.title}</Link>
                                    </div>
                                    <div className='overlay_date'>
                                        <p>- {blog.date}</p>
                                    </div>
                                    <div className='overlay_color'></div>
                                </div>
                                <div className='blog_text_container'>
                                    <p className='blog_text'>
                                        {blog.descn}
                                    </p>
                                </div>
                            </div>
                        </Link>
                    </div>
                ))}
            </div>

            {/* <div className='pagination_div'>
                <Pagination className="justify-content-start main-pagination">
                    <Pagination.Prev
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                    >
                        <FontAwesomeIcon icon={faArrowLeft} className="page-link-prev" />
                    </Pagination.Prev>

                    {Array.from({ length: totalPages }, (_, i) => (
                        <Pagination.Item
                            key={i + 1}
                            active={i + 1 === currentPage}
                            onClick={() => handlePageChange(i + 1)}
                        >
                            {i + 1 < 10 ? `0${i + 1}` : i + 1}
                        </Pagination.Item>
                    ))}

                    <Pagination.Next
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                    >
                        <FontAwesomeIcon icon={faArrowRight} className="page-link-next" />
                    </Pagination.Next>
                </Pagination>
            </div> */}


        </section>
    )
}

export default BlogsContent
