import React from 'react'
import './TopPosts.css'
import { Link } from 'react-router-dom'
import { blogsData } from '../../Common_Component/Array_response/Blogs/Blog_data'

const TopPosts = () => {
    return (
        <section className='TopPosts'>
            <div className='black_card_holder'>
                <div className='card_heading'>
                    <p>Top Posts</p>
                </div>
                <div className='content_holder'>
                    <div className='post_content_holder'>
                        {blogsData.map((data, index) => (
                            <div className='inner_div' key={data.id}>
                                <Link to={`/blog-detail/${data.id}`}>
                                    <div className='row'>
                                        <div className='col-lg-2 col-md-2 col-sm-2 col-2'>
                                            <div className='number_div'>
                                                <p>{data.id}</p>
                                            </div>
                                        </div>
                                        <div className='col-lg-10 col-md-10 col-sm-10 col-10'>
                                            <div className='text_holder'>
                                                <p>{data.title}</p>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TopPosts
