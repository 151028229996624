import React, { useEffect, useState } from 'react'
import './Blog_description.css'
import { useParams } from 'react-router-dom';

import { blogsData } from '../../Common_Component/Array_response/Blogs/Blog_data';

const Blog_description = () => {

    const { id } = useParams();
    const blog = blogsData.find((blog) => blog.id === parseInt(id));

    // const [filterblogs, setfilterblogs] = useState([]);

    // console.log("Blog_description", id)


    return (
        <>
            <section className='Blog_description_sec'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-11 mx-auto'>
                            <p className='descp'>{blog.descn}</p>
                            <div className='blstediv'>
                                <div className='row'>
                                    <div className='col-lg-4 mb-4'>
                                        <div className='listdiv'>
                                            <div>
                                                {blog?.blogpoints?.map((data, index) => (
                                                    <p className='list'><span>{data.id}.</span>{data.title}</p>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-8'>
                                        <div className='listdescdiv'>
                                            {blog?.blogpoints?.map((data, index) => (
                                                <div className='distdiv mb-2'>
                                                    <p className='titt'><span>{data.id}.</span>{data.title}</p>
                                                    <p className='anwer'>{data.discription}</p>
                                                </div>
                                            ))}

                                            {/* <div className='distdiv'>
                                                <p className='titt'><span>1.</span>Educate and Train Your Employees</p>
                                                <p className='anwer'>Your employees are often your first line of defense—and potentially your weakest link. Human error accounts for a significant number of data breaches, so:
                                                </p>
                                                <ul>
                                                    <li>Conduct regular cybersecurity awareness training.</li>
                                                    <li>Teach employees to identify phishing emails, fake websites, and suspicious links.</li>
                                                    <li>Encourage strong password practices, like using passphrases and enabling multi-factor authentication (MFA).</li>
                                                </ul>
                                                <p className='protip'>Pro Tip:</p>
                                                <p className='titt'>Use phishing simulation tools to test and reinforce employee readiness.</p>
                                            </div> */}
                                        </div>

                                        {/* <div className='finaldiv'>
                                            <p className='finalp'>Final Thoughts</p>
                                            <p className='cyb'>Cybersecurity isn’t a one-time fix; it’s an ongoing commitment. By implementing these five tips, you can build a strong defense against cyber threats and protect your business’s sensitive data.
                                            </p>
                                            <p className='stay'>Stay vigilant, stay secure!</p>
                                        </div> */}


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Blog_description