import { Link } from 'react-router-dom'
import './SocialMedia.css'
import React from 'react'

const SocialMedia = () => {
    return (
        <section className='SocialMedia'>

            <div className='black_card_holder'>
                <div className='card_heading'>
                    <p>Social Media Links</p>
                </div>
                <div className='content_holder'>
                    <div className='post_content_holder'>
                        <div className='inner_div1'>
                            <div className='row'>
                                <div className='col-xl-2 col-lg-3 col-md-3 col-sm-2 col-2'>
                                    <div className='icon_holder'>
                                        <div className='img_holder'>
                                            <img src={process.env.PUBLIC_URL + "/assets/images/blogs/social-media/insta.png"} />
                                        </div>

                                        <div className='bg_box'>
                                        </div>
                                    </div>


                                </div>

                                <div className='col-xl-10 col-lg-9 col-md-9 col-sm-10 col-9'>
                                    <div className='link_holder'>
                                        <Link to="https://www.instagram.com/profcymaglobal/?igsh=MW5idnF4bW16azBqNQ%3D%3D#" target='_blank'><p>https://www.instagram.com/profcymaglobal/</p></Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='inner_div1'>
                            <div className='row'>
                                <div className='col-xl-2 col-lg-3 col-md-3 col-sm-2 col-2'>
                                    <div className='icon_holder'>
                                        <div className='img_holder'>
                                            <img src={process.env.PUBLIC_URL + "/assets/images/blogs/social-media/linkdin.png"} />
                                        </div>
                                        <div className='bg_box'>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-xl-10 col-lg-9 col-md-9 col-sm-10 col-9'>
                                    <div className='link_holder'>
                                        <Link to="https://www.linkedin.com/company/profcyma-global-solutions/posts/?feedView=all" target='_blank'><p>https://www.linkedin.com/company/71181720 /admin/dashboard/</p></Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='inner_div1'>
                            <div className='row'>
                                <div className='col-xl-2 col-lg-3 col-md-3 col-sm-2 col-2'>
                                    <div className='icon_holder'>
                                        <div className='img_holder'>
                                            <img src={process.env.PUBLIC_URL + "/assets/images/blogs/social-media/twitter.png"} />
                                        </div>
                                        <div className='bg_box'>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-xl-10 col-lg-9 col-md-9 col-sm-10 col-9'>
                                    <div className='link_holder'>
                                        <Link to="#"><p>https://x.com/?logout=1732798679923</p></Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='inner_div1'>
                            <div className='row'>
                                <div className='col-xl-2 col-lg-3 col-md-3 col-sm-2 col-2'>
                                    <div className='icon_holder'>
                                        <div className='img_holder'>
                                            <img src={process.env.PUBLIC_URL + "/assets/images/blogs/social-media/fb.png"} />
                                        </div>

                                        <div className='bg_box'>
                                        </div>
                                    </div>


                                </div>

                                <div className='col-xl-10 col-lg-9 col-md-9 col-sm-10 col-9'>
                                    <div className='link_holder'>
                                        <Link to="https://www.facebook.com/profcymaglobal" target="_blank"><p>https://www.facebook.com/profcymaglobal</p></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SocialMedia
