import React from 'react'
import './Blog_details_banner.css'
import { useParams } from 'react-router-dom';
import { blogsData } from '../../Common_Component/Array_response/Blogs/Blog_data';

const Blog_details_banner = () => {

  const { id } = useParams();
  const blog = blogsData.find((blog) => blog.id === parseInt(id));


  return (
    <>
      <section className='Blog_details_banner'>
        <div className='container'>
          <div className='img-div'>
            <img className="banimg" src={process.env.PUBLIC_URL + blog.image} alt={blog.title} />
            <div className='overlay-color'></div>

            <div className='row'>
              <div className='col-lg-11 mx-auto'>
                <p className='blog-name'>{blog.title}</p>
                <p className='blog-date'>-{blog.date}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Blog_details_banner