import React, { useEffect } from 'react'
import Blog_bread from './blog-bread/Blog_bread'
import Blog_details_banner from './blog-details-banner/Blog_details_banner'
import Blog_description from './blog-description/Blog_description'

const Blog_details = () => {




  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Blog_bread />
      <Blog_details_banner />
      <Blog_description />
    </>
  )
}

export default Blog_details