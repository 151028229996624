import React, { useEffect, useState } from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { faPhone, faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faHeart,
  faUser,
} from "@fortawesome/free-regular-svg-icons";
import { faFacebookF, faInstagram, faYoutube } from "@fortawesome/free-brands-svg-icons";
import It_solution_offcanvas from "../Common_Component/offcanvas/It_Solution_offcanvas/It_solution_offcanvas";


const Footer = () => {
  const lnks = [
    {
      name: "Home",
      BaseLink: "/",
    },
    {
      name: "Who we are",
      BaseLink: "/who-we-are",
    },
    {
      name: "Portfolio",
      BaseLink: "/portfolio",
    },
    {
      name: "IT Solutions",
      BaseLink: "#",

    },
    {
      name: "Gallery",
      BaseLink: "/gallery",
    },
    {
      name: "Blogs",
      BaseLink: "/blogs",
    },
    {
      name: "Career",
      BaseLink: "/career",
    },
    {
      name: "Contact us",
      BaseLink: "/contact-us",
    }
  ];


  const [showOffcanvas, setShowOffcanvas] = React.useState(false);
  const handleOffcanvasToggle = () => setShowOffcanvas((prev) => !prev);



  return (
    <>
      <section className="footer_div">
        <Container>
          <Row >
            <Col xxl={8}
              xl={9}
              lg={10}
              md={11}
              sm={11}
              xs={12}
              className="mx-auto">

              <div className="footermain_div">


                <div className="footer_top">

                  <div className="all_icon padding_div">
                    <Link to={"https://www.facebook.com/profcymaglobal"} target="blank">
                      <div className="headericn_div">
                        <FontAwesomeIcon className="cart_logo" icon={faFacebookF} />
                      </div>
                    </Link>
                    <Link to={"https://www.instagram.com/profcymaglobal?igsh=MW5idnF4bW16azBqNQ=="} target="blank">
                      <div className="headericn_div">
                        <FontAwesomeIcon icon={faInstagram} className="cart_logo" />
                      </div>
                    </Link>
                    <Link to={"https://www.youtube.com/@profcymacareersolutionsllp8277"} target="blank">
                      <div className="headericn_div">
                        <FontAwesomeIcon icon={faYoutube} className="cart_logo" />
                      </div>
                    </Link>
                  </div>

                  <div className="d-flex justify-content-around flex-wrap">
                    {/* {lnks.map((text, index) => ( */}

                    <div className="top_div_footer  ">
                      <Link to="/" >
                        <p className="top_text px-2">Home</p>
                      </Link>
                    </div>
                    <div className="top_div_footer  ">
                      <Link to="/who-we-are" >
                        <p className="top_text px-2">Who we are</p>
                      </Link>
                    </div>
                    <div className="top_div_footer  ">
                      <Link to="/portfolio" >
                        <p className="top_text px-2">Portfolio</p>
                      </Link>
                    </div>
                    <div className="top_div_footer  " onClick={handleOffcanvasToggle}>
                      {/* <Link to="#" >  */}
                      <p className="top_text px-2">IT Solutions</p>
                      {/* </Link> */}
                    </div>
                    <div className="top_div_footer  ">
                      <Link to="/gallery" >
                        <p className="top_text px-2">Gallery</p>
                      </Link>
                    </div>
                    <div className="top_div_footer  ">
                      <Link to="/blogs" >
                        <p className="top_text px-2">Blogs</p>
                      </Link>
                    </div>
                    <div className="top_div_footer  ">
                      <Link to="/career" >
                        <p className="top_text px-2">Career</p>
                      </Link>
                    </div>
                    <div className="top_div_footer  ">
                      <Link to="/contact-us" >
                        <p className="top_text px-2">Contact us</p>
                      </Link>
                    </div>

                    {/* ))} */}
                  </div>
                </div>

                <div className="footer_miidle">
                  <Row>
                    <Col
                      xxl={8}
                      xl={8}
                      lg={7}
                      md={8}
                      sm={10}
                      xs={12}
                      className="mx-auto"
                    >
                      <div className="top_div_footer">
                        <div className="addres_div padding_div">
                          <p className="top_text">
                            2nd Floor, Office No 3, M No. 734, Opp High Class
                            Society, Pashan Rd, near Suryadutta College, Patil
                            Nagar, Bavdhan, Pune, Maharashtra 411021
                          </p>
                        </div>
                        <div className="contact_div padding_div">
                          <p className="top_text">
                            <FontAwesomeIcon icon={faEnvelope} className="footericn" />
                            {" "}
                            info@profcymaglobal.com
                          </p>
                          <p className="top_text">
                            <FontAwesomeIcon icon={faPhone} className="footericn" />{" "}
                            +91 960 775 5557 | 940 570 90425
                          </p>
                        </div>



                      </div>
                    </Col>
                  </Row>
                </div>

                <p className="bottom_footer_text">
                  © 2024 Profcymaglobal. All Rights Reserved. Developed by <Link target="_blank" className="bottom_footer_text" to="https://profcymaglobal.com">Profcyma</Link>
                </p>
              </div>
            </Col>

          </Row>
        </Container>


      </section>

      <It_solution_offcanvas
        showOffcanvas={showOffcanvas}
        handleClose={() => setShowOffcanvas(false)}
      />
    </>
  );
};

export default Footer;
