import React, { useRef, useState ,useEffect } from 'react';

import "./Banner.css";
import axios from "axios";

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';


function Banner() {

  const bannerdata = [
    {
      maintitle: "Your Technology Partner for Tomorrow",
      subtitle: "Empowering businesses with Cutting-edge IT solutions",
      banner: "/assets/images/home/banner/slide3.jpg"
    },
    {
      maintitle: "Profcyma is",
      subtitle: "IT solutions & services company, best agency, business, digital, network solution.",
      banner: "/assets/images/home/banner/slide1.png"
    },
    {
      maintitle: "Profcyma",
      subtitle: "Where Vision Meets Technology",
      banner: "/assets/images/home/banner/slide2.jpg"
    },


  ];

  // const vidRef = useRef();

  // useEffect(() => {
  //   vidRef.current.play();
  // }, []);


  return (
    <section className="home-banner">

      <div className="container p-0">
        <div className='slider-main'>
          <Swiper
            centeredSlides={true}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            navigation={true}
            modules={[Autoplay, Navigation, Pagination,]}
            className="mySwiper"
          >
            {bannerdata.map((data, index) => (
              <SwiperSlide>
                <div className='slider-div'>
                  <div className='row'>
                    <div className='col-lg-4 col-md-5 my-auto order-md-1 order-2'>
                      <div className='sec1'>
                        <h1 className='maintitle'>{data.maintitle}</h1>
                        <div className='ttbd'></div>
                        <p className='subtext'>{data.subtitle}</p>
                      </div>
                    </div>
                    <div className='col-lg-8 col-md-7 order-md-2 order-1'>
                      <div className='sec2'>
                        <img className='slideimg' src={process.env.PUBLIC_URL + data.banner} />
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}






          </Swiper>
        </div>
      </div>


      {/* <div className='container-fluid p-0'>
        <div className='videosec'>
          <video src={process.env.PUBLIC_URL + "/assets/video/banner/Video_2.mp4"} ref={vidRef} muted autoplay loop playsinline className=" video" />
        </div>
      </div> */}


    </section>
  );
}

export default Banner;
