import React, { useRef, useState } from 'react';
import './Tab_compo_port.css'
import { Swiper, SwiperSlide } from 'swiper/react';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import { Link } from 'react-router-dom';

import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';

const Tab_compo = () => {

    const ecom_mob_app = [
        {
            name: "mahatrade",
            logo: "",
            image: "",
            link: "https://play.google.com/store/apps/details?id=com.mahatrade&hl=en_IN",
            content: "We created Mahatrade App to revolutionize trading with AI-powered automation, offering easy processes, top features, and consistent profitability."
        },
        {
            name: "rexi",
            logo: "",
            image: "/assets/images/portfolio/mob-application/Rexi.png",
            link: "https://play.google.com/store/apps/details?id=com.profcymasolutions.otowala&hl=en_IN",
            content: "REXI App offers a simple and efficient transportation service, designed to make your travel experience convenient and hassle-free."
        },
        {
            name: "Best Property",
            logo: "",
            image: "",
            link: "https://play.google.com/store/apps/details?id=com.profcyma_solution.best_property_24&hl=en_IN",
            content: "contributing to their mission of simplifying property searches through expert real estate services, specializing in rentals and sales."
        },
        {
            name: "vastu",
            logo: "",
            image: "",
            link: "https://play.google.com/store/apps/details?id=co.thor.msjwb&hl=en_IN",
            content: "Founded in 2007, Vastu Spandan offers training and consultancy in Indian Vaastu Shastra. Our mission is to spread this ancient knowledge globally, combining modern architecture with traditional principles through workshops, courses, and professional training."
        },
        {
            name: "Surjabasha",
            logo: "",
            image: "",
            link: "https://play.google.com/store/apps/details?id=com.codebeat.surjabasha&hl=en_IN",
            content: "Surjabasha is committed to delivering exceptional healthcare services, combining modern technology with compassionate treatment."
        },

    ];

    const mob_game_app = [
        {
            image: "/assets/images/portfolio/game-application/game1.png",
            name: "roullete"
        },
        {
            image: "/assets/images/portfolio/game-application/vip11.png",
            name: "vip11"
        },
        {
            image: "/assets/images/portfolio/game-application/bailgada.png",
            name: "bailgada"
        },

    ];

    const social_media_app = [
        {
            name: "meet-x",
            logo: "",
            image: "",
            link: "https://play.google.com/store/apps/details?id=com.profcymasolutions.meetx&hl=en_IN",
            content: "REXI App offers a simple and efficient transportation service, designed to make your travel experience convenient and hassle-free."
        },
        {
            name: "meet-x",
            logo: "",
            image: "",
            link: "https://play.google.com/store/apps/details?id=com.profcymasolutions.meetx&hl=en_IN",
            content: "REXI App offers a simple and efficient transportation service, designed to make your travel experience convenient and hassle-free."
        },
    ];



    const ecom_website = [
        {
            name: "mansa sutri",
            logo: "/assets/images/portfolio/project-logo/mansa.png",
            image: "/assets/images/portfolio/website/mansa-nutri.png",
            content: "At Mansa nutri, we believe in science-backed, sustainable nutrition plans to help you achieve your health goals. Whether you’re looking to lose weight, gain muscle, or simply eat better, we’re here to guide you every step of the way.",
            link: "https://mansanutri.com/"
        },
        {
            name: "CMJ",
            logo: "/assets/images/portfolio/project-logo/cmj.png",
            image: "/assets/images/portfolio/website/cmj.png",
            content: "The tape industry is set for rapid growth, driven by technological advancements enhancing durability, efficiency, and diverse application possibilities.",
            link: "https://cmjindustries.in/"
        },
        {
            name: "Boomerbucks",
            logo: "/assets/images/portfolio/project-logo/boomerbucks.png",
            image: "/assets/images/portfolio/website/BoomerBucks_website.png",
            content: "Crafted engaging product descriptions and SEO-friendly content for BoomerBuks, enhancing user experience and driving conversions on their e-commerce platform.",
            link: "http://boomerbucks.in/"
        },
        {
            name: "Flick N Shoot",
            logo: "",
            image: "/assets/images/portfolio/website/FnS_Website_mockup.png",
            content: "Flexible, affordable, and fully equipped for all your creative or professional needs.",
            link: "https://localmandai.com/"
        },
        {
            name: "Shubharambh",
            logo: "",
            image: "/assets/images/portfolio/website/Shubharambh_Website_mockup.png",
            content: "Flexible, affordable, and fully equipped for all your creative or professional needs.",
            link: "https://localmandai.com/"
        },
        {
            name: "Local Mandai",
            logo: "",
            image: "",
            content: "Local Mandai is an e-commerce platform connecting buyers with fresh, local produce and goods. We bring the market to your doorstep with quality and convenience.",
            link: "https://localmandai.com/"
        },
        {
            name: "Way2Z",
            logo: "",
            image: "",
            content: "Waytoz is an innovative courier aggregator platform, simplifying logistics for businesses with tech-driven solutions. Offering affordable online shopping across all categories, we ensure seamless shipping and enhanced customer satisfaction.",
            link: "https://waytoz.com/"
        },
        {
            name: "Purevia",
            logo: "",
            image: "",
            content: "We are committed to bringing you 100% natural, herbal products to improve your health, skin, and overall well-being, crafted with the finest ingredients from nature.",
            link: "https://purevianaturals.com/"
        },


    ];

    const ecom_game_website = [
        {
            image: "/assets/images/portfolio/game-website/fastlot.png",
            link: "#"
        },
        {
            image: "/assets/images/portfolio/game-website/om-winners.png",
            link: "#"
        },
        {
            image: "/assets/images/portfolio/game-website/fastlot.png",
            link: "#"
        },


    ];

    const landing_page_website = [
        {
            name: "fyc",
            logo: "/assets/images/portfolio/project-logo/fyc.png",
            image: "/assets/images/portfolio/website-landingpage/landingpage.png",
            content: "We are passionate about bringing you the best in product category i.e fashion, electronics, home goods, etc. Our journey began with a simple goal to provide high quality products that cater to the diverse needs of our customers.",
            link: "#",
        },
        {
            name: "To Be Blissful",
            logo: "/assets/images/portfolio/project-logo/blissfull.png",
            image: "",
            content: "We created a platform offering expert-led courses in yoga, pranayam, meditation, and swar yoga to inspire holistic growth and well-being.",
            link: "https://tobeblissful.in/",
        },
        {
            name: "Choudhary Scaffolding",
            logo: "/assets/images/portfolio/project-logo/choudhari.png",
            image: "",
            content: "We created a website for Choudhary Scaffolding Contractors that has been a trusted provider of turnkey scaffolding solutions, offering rental & sales of MS Cup lock, ladders, trolleys, and accessories.",
            link: "https://www.choudharyscaffolding.co.in/",
        },
        {
            name: "Rizwan",
            logo: "/assets/images/portfolio/project-logo/rizwan_wifi.png",
            image: "",
            content: "Rizwan WiFi Services offers reliable, high-speed internet solutions for homes and businesses, ensuring seamless connectivity and exceptional customer support.",
            link: "http://www.rizwanwifi.com/",
        },
        {
            name: "Hostel Bliss",
            logo: "/assets/images/portfolio/project-logo/hostel_logo.png",
            image: "",
            content: "At Hostel Bliss, we offer more than just a place to stay – we create a vibrant, inclusive community where guests connect, share experiences, and build lasting friendships.",
            link: "https://www.hostelbliss.com/",
        },
        {
            name: "Hi Tech",
            logo: "/assets/images/portfolio/project-logo/hitech-logo.png",
            image: "",
            content: "Hitech Solutions: 23 Years of Trusted Insurance Services at Hitech Solutions, we have proudly served our customers for over 23 years, offering reliable insurance solutions for motor, health, travel, and property.",
            link: "https://hitechsolutionsins.com",
        },
        {
            name: "Lkomodaki",
            logo: "/assets/images/portfolio/project-logo/lkamodiki.png",
            image: "",
            content: "We are an established Turning Mechanical Engineering organization providing services in the PUNE. Our operations based in Bhosari Industrial Area with the motive of better service.",
            link: "https://lkaumodaki.com/",
        },

    ];




    return (
        <>
            <section className='Tab_compo_port'>
                <div className='container'>
                    <div className='text-center'>
                        <h1 className='whotitle'>Portfolio</h1>
                        <h5 className='secsub-title'></h5>
                    </div>

                    <div className='tabsec'>
                        <Tab.Container id="left-tabs-example" defaultActiveKey="website">
                            <Nav variant="pills" className="flex-row">
                                <Nav.Item>
                                    <Nav.Link eventKey="website">Website</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="mobapp">Mobile Application</Nav.Link>
                                </Nav.Item>

                            </Nav>

                            <Tab.Content>
                                <Tab.Pane eventKey="mobapp">
                                    <h5 className='secsub-title my-4'>Our E-commerce Mob applications</h5>
                                    {/* Our E-commerce Mob applications section start */}
                                    <div className='row my-5'>
                                        {ecom_mob_app.map((data, index) => (
                                            <div className='col-lg-4 col-sm-6 col-10 mx-auto mb-4'>
                                                <Link to={data.link} target='_blank'>
                                                    <div className='project-card'>
                                                        <div className='logodiv'>
                                                            <img className='logo' src={process.env.PUBLIC_URL + data.logo} />
                                                        </div>

                                                        <div className='image-div'>
                                                            <img className='projimg' src={process.env.PUBLIC_URL + data.image} />
                                                        </div>
                                                        <div className='cont-div'>
                                                            <p className='content'>{data.content}</p>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        ))}
                                    </div>
                                    {/* Our E-commerce Mob applications section end */}

                                    {/* Our Mobile Gaming Applications section start */}
                                    <div className='Mobile_gaming_sec'>
                                        <div className='bg-div'>
                                            <h5 className='secsub-title my-4 ms-5'>Our Mobile Gaming Applications</h5>

                                            <div className='slider-sec'>
                                                <Swiper navigation={true}
                                                    modules={[Navigation]}
                                                    className="mySwiper"
                                                    breakpoints={{
                                                        '0': {
                                                            slidesPerView: 1,
                                                            spaceBetween: 10,
                                                        },
                                                        '575': {
                                                            slidesPerView: 2,
                                                            spaceBetween: 20,
                                                        },
                                                        '768': {
                                                            slidesPerView: 3,
                                                            spaceBetween: 20,
                                                        },
                                                        '1400': {
                                                            slidesPerView: 3,
                                                            spaceBetween: 20,
                                                        },
                                                    }}
                                                >
                                                    {mob_game_app.map((data, index) => (
                                                        <SwiperSlide>
                                                            <div className='slider-div'>
                                                                <img className='gameimg' src={process.env.PUBLIC_URL + data.image} />
                                                            </div>
                                                        </SwiperSlide>
                                                    ))}
                                                </Swiper>
                                            </div>

                                        </div>
                                    </div>
                                    {/* Our Mobile Gaming Applications section end  */}


                                    {/* Our Social Mob applications  section start */}
                                    <div className='ecommob-sec'>
                                        <h5 className='secsub-title my-4'>Our Social Mob applications</h5>
                                        <div className='row'>
                                            {social_media_app.map((data, index) => (
                                                <div className=' col-md-6 col-12 mx-auto mb-4'>
                                                    <div className='project-card'>
                                                        <div className='logodiv'>
                                                            <img className='logo' src={process.env.PUBLIC_URL + data.logo} />
                                                        </div>
                                                        <div className='row'>
                                                            <div className='col-6 my-auto'>
                                                                <div className='cont-div'>
                                                                    <p className='content'>{data.content}</p>
                                                                </div>
                                                            </div>
                                                            <div className='col-6'>
                                                                <div className='image-div'>
                                                                    <img className='projimg' src={process.env.PUBLIC_URL + data.image} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    {/* Our Social Mob applications  section end */}

                                </Tab.Pane>

                                <Tab.Pane eventKey="website">
                                    <h5 className='secsub-title my-4'>Our E-commerce Websites</h5>

                                    {/* Our E-commerce Websites section start*/}
                                    <div className='row my-5'>
                                        {ecom_website.map((data, index) => (
                                            <div className='col-md-6 mb-4'>
                                                <Link to={data.link} target="_blank">
                                                    <div className='website-card'>
                                                        <div className='logo-div'>
                                                            <img className='logo' src={process.env.PUBLIC_URL + data.logo} />
                                                        </div>
                                                        <div className='img-div'>
                                                            <img className='webimg' src={process.env.PUBLIC_URL + data.image} />
                                                        </div>
                                                        <div className='content-div'>
                                                            <p className='content'>{data.content}</p>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        ))}



                                    </div>
                                    {/* Our E-commerce Websites section end*/}


                                    {/* Our Mobile Gaming Websites section start  */}
                                    <div className='Mobile_gaming_sec'>
                                        <div className='bg-div'>
                                            <h5 className='secsub-title my-4 ms-5'>Our Gaming Websites</h5>

                                            <div className='slider-sec'>
                                                <Swiper navigation={true}
                                                    modules={[Navigation]}
                                                    className="mySwiper"
                                                    breakpoints={{
                                                        '0': {
                                                            slidesPerView: 1,
                                                            spaceBetween: 10,
                                                        },
                                                        '575': {
                                                            slidesPerView: 2,
                                                            spaceBetween: 20,
                                                        },
                                                        '768': {
                                                            slidesPerView: 3,
                                                            spaceBetween: 20,
                                                        },
                                                        '1400': {
                                                            slidesPerView: 3,
                                                            spaceBetween: 20,
                                                        },
                                                    }}
                                                >
                                                    {ecom_game_website.map((data, index) => (
                                                        <SwiperSlide>
                                                            <div className='slider-div'>
                                                                <img className='gameimg' src={process.env.PUBLIC_URL + data.image} />
                                                            </div>
                                                        </SwiperSlide>
                                                    ))}
                                                </Swiper>
                                            </div>

                                        </div>
                                    </div>
                                    {/* Our Mobile Gaming Websites section end  */}

                                    {/* ourweb-land-sec section start  */}
                                    <div className='ourweb-land-sec'>
                                        <h5 className='secsub-title my-4'>Our Websites Landing pages</h5>

                                        <div className='row my-5'>
                                            {landing_page_website.map((data, index) => (
                                                <div className='col-lg-4 col-sm-6 mb-4'>
                                                    <Link to={data.link} target='_blank'>
                                                        <div className='website-card'>
                                                            <div className='logo-div'>
                                                                <img className='logo' src={process.env.PUBLIC_URL + data.logo} />
                                                            </div>
                                                            <div className='content-div'>
                                                                <p className='content'>{data.content}</p>
                                                            </div>
                                                            <div className='img-div'>
                                                                <img className='webimg' src={process.env.PUBLIC_URL + data.image} />
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    {/* ourweb-land-sec section end  */}
                                </Tab.Pane>


                            </Tab.Content>
                        </Tab.Container>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Tab_compo