import React from "react";
import "./jobs.css";
import Form from "react-bootstrap/Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faLocationDot,
  faMagnifyingGlass,
  faSortDown,
} from "@fortawesome/free-solid-svg-icons";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import Pagination from "react-bootstrap/Pagination";
import { Link } from "react-router-dom";

const Jobs = () => {

  const jobdata = [
    {
      id: 1,
      job_role: "Node.js Developer",
      job_department: "Backend developer",
      time: "Full time",
      location: "Pune",
      job_details: "We are looking for a highly capable Node.js developer to optimize our web-based application performance. You will be collaborating with our front-end application developers, designing back-end components, and integrating data storage and protection solutions.",

      responsibility: [
        {
          respo: "Must have work experience with Flutter SDK and Dart programming language."
        },
        {
          respo: "Experience with third-party libraries and APIs."
        },
        {
          respo: "Experience working with Firebase database"
        },
        {
          respo: "Strong knowledge on Flutter widgets for both Android/iOS"
        },
      ]
    },

    {
      id: 2,
      job_role: "Javascript Developer",
      job_department: "Frontend developer",
      time: "Full time",
      location: "Pune",
      job_details: "We are looking for a highly capable Node.js developer to optimize our web-based application performance. You will be collaborating with our front-end application developers, designing back-end components, and integrating data storage and protection solutions.",

      responsibility: [
        {
          respo: "Must have work experience with Flutter SDK and Dart programming language."
        },
        {
          respo: "Experience with third-party libraries and APIs."
        },
        {
          respo: "Experience working with Firebase database"
        },
        {
          respo: "Strong knowledge on Flutter widgets for both Android/iOS"
        },
      ]
    },
    {
      id: 3,
      job_role: "PHP Developer",
      job_department: "Backend developer",
      time: "Full time",
      location: "Pune",
      job_details: "We are looking for a highly capable Node.js developer to optimize our web-based application performance. You will be collaborating with our front-end application developers, designing back-end components, and integrating data storage and protection solutions.",
      responsibility: [
        {
          respo: "Must have work experience with Flutter SDK and Dart programming language."
        },
        {
          respo: "Experience with third-party libraries and APIs."
        },
        {
          respo: "Experience working with Firebase database"
        },
        {
          respo: "Strong knowledge on Flutter widgets for both Android/iOS"
        },
      ]

    },
  ];


  return (
    <>
      <section className="jobssec">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 mx-auto">
              {/* <div className="filterdiv">
                <Form>
                  <div className="row">
                    <div className="col-lg-4 col-sm-6 mb-3">
                      <div className="form-group">
                        <Form.Control type="text" placeholder="Search" />
                        <button className="srcbtn" type="button">
                          <FontAwesomeIcon
                            className="srchicn"
                            icon={faMagnifyingGlass}
                          />
                        </button>
                      </div>
                    </div>
                    <div className="col-lg-2 col-sm-6 mb-3 ">
                      <div className="form-group">
                        <Form.Select aria-label="Default select example">
                          <option>Job Category</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </Form.Select>
                        <button className="srcbtn" type="button">
                          <FontAwesomeIcon
                            className="srchicn"
                            icon={faSortDown}
                          />
                        </button>
                      </div>
                    </div>
                    <div className="col-lg-2 col-sm-6 mb-3">
                      <div className="form-group">
                        <Form.Select aria-label="Default select example">
                          <option>Job Type</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </Form.Select>
                        <button className="srcbtn" type="button">
                          <FontAwesomeIcon
                            className="srchicn"
                            icon={faSortDown}
                          />
                        </button>
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 mb-3">
                      <div className="form-group">
                        <Form.Select aria-label="Default select example">
                          <option>Job Location</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </Form.Select>
                        <button className="srcbtn" type="button">
                          <FontAwesomeIcon
                            className="srchicn"
                            icon={faSortDown}
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </Form>
              </div> */}

              <div className="cardsec">

                <div className="row">
                  {jobdata.map((data, index) => (
                    <div className="col-lg-4 col-sm-6 col-12 mb-4" key={data.id}>
                      <div className="job-card">
                        <div className="row">
                          <div className="col-9">
                            <div className="info-text-holder">
                              <p className="techname">{data.job_department}</p>
                              <p className="devhname">{data.job_role}</p>
                              <p className="devhname">
                                <FontAwesomeIcon icon={faClock} />
                                {data.time}
                              </p>
                              <p className="devhname">
                                <FontAwesomeIcon icon={faLocationDot} />
                                {data.location}
                              </p>
                            </div>
                          </div>
                          <div className="col-3 text-end">
                            <Link to={`/career-details/${data.id}`} >
                              <div className="redirectdiv">
                                <div className="nextdiv">
                                  <FontAwesomeIcon icon={faArrowRight} />
                                </div>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>


                {/* <div className="paginationdiv">
                  <Pagination>
                    <Pagination.Prev />
                    <Pagination.Item active>{1}</Pagination.Item>
                    <Pagination.Item>{2}</Pagination.Item>
                    <Pagination.Item>{3}</Pagination.Item>
                    <Pagination.Next />
                  </Pagination>
                </div> */}


              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Jobs;
