import React, { useEffect, useState } from 'react'
import './Car_details.css'
import Job_apply_form from '../job-apply-form/Job_apply_form'
import { useParams } from 'react-router-dom';


const Car_details = () => {

    const { id } = useParams();
    const [jobresponsibility, SetJobresponsibility] = useState([]);
    // console.log("jobresponsibility",id)

    const jobdata = [
        {
            id: 1,
            job_role: "Node.js Developer",
            job_department: "Backend developer",
            time: "Full time",
            location: "Pune",
            job_details: "We are looking for a highly capable Node.js developer to optimize our web-based application performance. You will be collaborating with our front-end application developers, designing back-end components, and integrating data storage and protection solutions.",

            responsibility: [
                {
                    respo: "Must have work experience with Flutter SDK and Dart programming language."
                },
                {
                    respo: "Experience with third-party libraries and APIs."
                },
                {
                    respo: "Experience working with Firebase database"
                },
                {
                    respo: "Strong knowledge on Flutter widgets for both Android/iOS"
                },
            ]
        },
        {
            id: 2,
            job_role: "Javascript Developer",
            job_department: "Frontend developer",
            time: "Full time",
            location: "Pune",
            job_details: "We are looking for a highly capable Node.js developer to optimize our web-based application performance. You will be collaborating with our front-end application developers, designing back-end components, and integrating data storage and protection solutions.",

            responsibility: [
                {
                    respo: "Must have work experience with Flutter SDK and Dart programming language."
                },
                {
                    respo: "Experience with third-party libraries and APIs."
                },
                {
                    respo: "Experience working with Firebase database"
                },
                {
                    respo: "Strong knowledge on Flutter widgets for both Android/iOS"
                },
            ]
        },
        {
            id: 3,
            job_role: "PHP Developer",
            job_department: "Backend developer",
            time: "Full time",
            location: "Pune",
            job_details: "We are looking for a highly capable Node.js developer to optimize our web-based application performance. You will be collaborating with our front-end application developers, designing back-end components, and integrating data storage and protection solutions.",
            responsibility: [
                {
                    respo: "Must have work experience with Flutter SDK and Dart programming language."
                },
                {
                    respo: "Experience with third-party libraries and APIs."
                },
                {
                    respo: "Experience working with Firebase database"
                },
                {
                    respo: "Strong knowledge on Flutter widgets for both Android/iOS"
                },
            ]

        },
    ];

    const job = jobdata.find((job) => job.id === parseInt(id));

    useEffect(() => {
        const job = jobdata.find((job) => Number(job.id) === Number(id));
        // const blog = blogsData.find((blog) => Number(blog.id) === Number(id));
        SetJobresponsibility(job);
    }, [jobdata])

    return (
        <>
            <section className='Car_details'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-7'>
                            <div className='sec1'>
                                <div className='main-roles'>
                                    <div className='row'>
                                        <div className='col-lg-10 mx-auto'>
                                            <div className='row mainjbbg'>
                                                <div className='col-4'>
                                                    <p className='mainjobrol'>Job Role:</p>
                                                </div>
                                                <div className='col-8'>
                                                    <p className='mainjobrol'>{job.job_role}</p>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-4'>
                                                    <p className='mainjobrol'>Job Department:</p>
                                                </div>
                                                <div className='col-8'>
                                                    <p className='mainjobrol'>{job.job_department}</p>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-4'>
                                                    <p className='mainjobrol'>Job Location:</p>
                                                </div>
                                                <div className='col-8'>
                                                    <p className='mainjobrol'>{job.location}</p>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-4'>
                                                    <p className='mainjobrol'>Job Type:</p>
                                                </div>
                                                <div className='col-8'>
                                                    <p className='mainjobrol'>{job.time}</p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-lg-10 mx-auto'>
                                        <p className='mainjobrol'>Job Details:</p>
                                        <p className='jobdesc'>{job.job_details}</p>

                                        <p className='jobdesc undrl'>Responsibilities include:</p>

                                        <ul>
                                            {jobresponsibility?.responsibility?.map((data, index) => (
                                                <li className='jobdesc'>{data.respo}</li>
                                            ))}
                                        </ul>


                                        {/* <ul>
                                            <li className='jobdesc'>Must have work experience with Flutter SDK and Dart programming language.
                                            </li>
                                            <li className='jobdesc'> Experience with third-party libraries and APIs.
                                            </li>
                                            <li className='jobdesc'>Experience working with Firebase database
                                            </li>
                                            <li className='jobdesc'>Strong knowledge on Flutter widgets for both Android/iOS
                                            </li>
                                            <li className='jobdesc'> Good knowledge in OOPS concepts
                                            </li>
                                        </ul> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-5'>
                            <Job_apply_form />

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Car_details