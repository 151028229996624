import React from 'react'
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faHouse } from '@fortawesome/free-solid-svg-icons';
import It_solution_offcanvas from "../../../Common_Component/offcanvas/It_Solution_offcanvas/It_solution_offcanvas"

const Service_breadcrumb = () => {

    const [showOffcanvas, setShowOffcanvas] = React.useState(false);
    const handleOffcanvasToggle = () => setShowOffcanvas((prev) => !prev);


    return (
        <>
            <section className='Blog_bread_sec'>
                <div className='container'>
                    <Breadcrumb>
                        <Breadcrumb.Item href="/"><FontAwesomeIcon icon={faHouse} /></Breadcrumb.Item>
                        <Breadcrumb.Item href="#" onClick={handleOffcanvasToggle}><FontAwesomeIcon icon={faChevronRight} />IT Solutions</Breadcrumb.Item>
                        <Breadcrumb.Item href="#" active><FontAwesomeIcon icon={faChevronRight} />Software Development</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            </section>

            <It_solution_offcanvas
                    showOffcanvas={showOffcanvas}
                    handleClose={() => setShowOffcanvas(false)}
                />
        </>
    )
}

export default Service_breadcrumb